import React from 'react';
import App from './App';
import ApolloClient from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { createHttpLink } from 'apollo-link-http';
import { ApolloProvider } from '@apollo/react-hooks';
const { REACT_APP_ENVIRONMENT } = process.env;
const httpLink = createHttpLink({
	uri:
		REACT_APP_ENVIRONMENT == 'development'
			? 'http://localhost:5000'
			: 'https://api.blizzapp.timacumdev.com',
});

const client = new ApolloClient({
	link: httpLink,
	cache: new InMemoryCache(),
});

export default (
	<ApolloProvider client={client}>
		<App />
	</ApolloProvider>
);
