import React from 'react';

function RowItem(props) {
	const itemPrice = parseInt(props.itemPrice);
	let goldPrice = parseInt(Math.floor(itemPrice / 10000));
	let silverPrice = (itemPrice % 10000) / 100;
	let copperPrice = (itemPrice % 100) / 100;
	return (
		<li className={'auction-item'}>
			<div className='img-container'>
				<div className='Icon frame'></div>
				<img alt='Item Icon' src={props.icon}></img>
			</div>
			<p className={'name ' + props.quality}>{props.name}</p>
			<p className='qty'>{props.quantity}</p>
			<p className='item-invtype'>{props.subClass + ' ' + props.invType}</p>
			<p className='price'>
				{goldPrice.toLocaleString()}
				<span className='gold'></span> {silverPrice}
				<span className='silver'></span>
				{copperPrice} <span className='copper'></span>
			</p>
		</li>
	);
}
export default RowItem;
