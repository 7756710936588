import React, { useState, useEffect, useRef } from 'react';
import ItemList from './itemList.js';
import './App.css';
import { useQuery } from '@apollo/react-hooks';
import headerImg from './assets/Logo.png';
import downArrow from './assets/arrow-down.svg';
import upArrow from './assets/arrow-up.svg';
import wowSearch from './assets/wow-search.svg';
import loader from './assets/preloader-transparent.svg';
import footerLeft from './assets/footer_detail_left.png';
import footerRight from './assets/footer_detail_right.png';
import closeButton from './assets/close-button.svg';
import gql from 'graphql-tag';

const GET_ITEM_COUNT_QUERY = gql`
	query getItemCount($sortBy: SortBy) {
		getItemCount(sortBy: $sortBy)
	}
`;
console.log('push 2');
function App() {
	const [order, setData] = useState({
		filterField: 'subclass',
		filterField2: 'invType',
		filterValue: '',
		filterValue2: '',
		searchValue: '',
		sortField: 'auctionID',
		order: 'DESC',
	});

	const { countLoading, data: { getItemCount: itemCount } = {} } = useQuery(
		GET_ITEM_COUNT_QUERY,
		{
			variables: {
				sortBy: {
					filterField: order.filterField,
					filterField2: order.filterField2,
					filterValue: order.filterValue,
					filterValue2: order.filterValue2,
					order: order.order,
					sortField: order.sortField,
					searchValue: order.searchValue,
				},
			},
		}
	);
	function useOutsideAlerter(ref) {
		useEffect(() => {
			/**
			 * Alert if clicked on outside of element
			 */
			function handleClickOutside(event) {
				if (ref.current && !ref.current.contains(event.target)) {
					alert('You clicked outside of me!');
				}
			}
			// Bind the event listener
			document.addEventListener('mousedown', handleClickOutside);
			return () => {
				// Unbind the event listener on clean up
				document.removeEventListener('mousedown', handleClickOutside);
			};
		}, [ref]);
	}

	const handleSort = (e) => {
		e.persist();
		let toggleOrder = order.order === 'DESC' ? 'ASC' : 'DESC';
		e.target.classList.add('active-sort');
		setData((prevState) => {
			return {
				...prevState,
				sortField: e.target.dataset.sortfield,
				order: toggleOrder,
			};
		});
	};

	let searchTimeout;
	const handleSearch = (e) => {
		e.persist();
		if (e.target.value.length >= 3 || e.target.value.length === 0) {
			if (searchTimeout) {
				clearTimeout(searchTimeout);
			}
			searchTimeout = setTimeout(() => {
				setData((prevState) => {
					return { ...prevState, searchValue: e.target.value };
				});
			}, 500);
		}
	};

	const handleFilter = (e) => {
		e.persist();
		document.querySelector('.search-item').classList.remove('active');
		document.querySelector('.search-container').classList.remove('visible');
		document.querySelector('.search-icon').src = wowSearch;
		setData((prevState) => {
			return {
				...prevState,
				filterValue: e.target.dataset.filtervalue,
				filterValue2: e.target.dataset.filtervalue2,
			};
		});
	};
	const handleFilterHoverEnter = (e) => {
		// document.querySelector('.search-item').classList.remove('active');
		// document.querySelector('.search-container').classList.remove('visible');
		// document.querySelector('.search-icon').src = wowSearch;
		console.log('hover');
	};
	const handleSearchClick = (e) => {
		document.querySelector('.search-icon').src = document
			.querySelector('.search-item')
			.classList.contains('active')
			? wowSearch
			: closeButton;
		document.querySelector('.search-item').classList.toggle('active');
		document.querySelector('.search-container').classList.toggle('visible');
		document.querySelector('.search-input').focus();
	};
	return (
		<div className='App'>
			<img
				className='header-image'
				alt='header'
				src={countLoading === true ? loader : headerImg}></img>
			<div className='main-container'>
				<div className='menu-container'>
					<div className='menu'>
						<div className='menu-item' onMouseEnter={handleFilterHoverEnter}>
							<p className='menu-title'>
								Weapons
								<img className='down-arrow' alt='arrow' src={downArrow}></img>
							</p>
							<div className='menu-dropdown'>
								<div className='category-container border'>
									<p className='category-title'>One-Handed</p>
									<p
										className='category-name'
										data-filtervalue='Dagger'
										data-filtervalue2='One-Hand'
										onClick={handleFilter}>
										Daggers
									</p>
									<p
										className='category-name'
										data-filtervalue='Fist Weapon'
										data-filtervalue2='One-Hand'
										onClick={handleFilter}>
										Fist Weapons
									</p>
									<p
										className='category-name'
										data-filtervalue='Axe'
										data-filtervalue2='One-Hand'
										onClick={handleFilter}>
										Axes
									</p>
									<p
										className='category-name'
										data-filtervalue='Mace'
										data-filtervalue2='One-Hand'
										onClick={handleFilter}>
										Maces
									</p>
									<p
										className='category-name'
										data-filtervalue='Sword'
										data-filtervalue2='One-Hand'
										onClick={handleFilter}>
										Swords
									</p>
									<p
										className='category-name'
										data-filtervalue='Warglaives'
										data-filtervalue2='One-Hand'
										onClick={handleFilter}>
										Warglaives
									</p>
								</div>
								<div className='category-container border'>
									<p className='category-title'>Two-Handed</p>
									<p
										className='category-name'
										data-filtervalue='Polearm'
										data-filtervalue2='Two-Hand'
										onClick={handleFilter}>
										Polearms
									</p>
									<p
										className='category-name'
										data-filtervalue='Staff'
										data-filtervalue2='Two-Hand'
										onClick={handleFilter}>
										Staves
									</p>
									<p
										className='category-name'
										data-filtervalue='Axe'
										data-filtervalue2='Two-Hand'
										onClick={handleFilter}>
										Axes
									</p>
									<p
										className='category-name'
										data-filtervalue='Mace'
										data-filtervalue2='Two-Hand'
										onClick={handleFilter}>
										Maces
									</p>
									<p
										className='category-name'
										data-filtervalue='Sword'
										data-filtervalue2='Two-Hand'
										onClick={handleFilter}>
										Swords
									</p>
								</div>
								<div className='category-container border'>
									<p className='category-title'>Ranged</p>
									<p
										className='category-name'
										data-filtervalue='Bow'
										data-filtervalue2='Ranged'
										onClick={handleFilter}>
										Bows
									</p>
									<p
										className='category-name'
										data-filtervalue='Crossbow'
										data-filtervalue2='Ranged'
										onClick={handleFilter}>
										Crossbows
									</p>
									<p
										className='category-name'
										data-filtervalue='Gun'
										data-filtervalue2='Ranged'
										onClick={handleFilter}>
										Guns
									</p>
									<p
										className='category-name'
										data-filtervalue='Wand'
										data-filtervalue2='Ranged'
										onClick={handleFilter}>
										Wands
									</p>
								</div>
								<div className='category-container'>
									<p
										className='category-title'
										data-filtervalue='Wand'
										data-filtervalue2='Ranged'
										onClick={handleFilter}>
										Other
									</p>
									<p
										className='category-name'
										data-filtervalue='Fishing Pole'
										data-filtervalue2='Two-Hand'
										onClick={handleFilter}>
										Fishing poles
									</p>
									<p
										className='category-name'
										data-filtervalue='Miscellaneous'
										data-filtervalue2='Other'
										onClick={handleFilter}>
										Miscellaneous
									</p>
								</div>
							</div>
						</div>
						<div className='menu-item'>
							<p className='menu-title'>
								Armor
								<img className='down-arrow' alt='arrow' src={downArrow}></img>
							</p>
							<div className='menu-dropdown'>
								<div className='category-container'>
									<p className='category-title'>Cloth</p>
									<p
										className='category-name'
										data-filtervalue='Cloth'
										data-filtervalue2='Chest'
										onClick={handleFilter}>
										Chest
									</p>
									<p
										className='category-name'
										data-filtervalue='Cloth'
										data-filtervalue2='Feet'
										onClick={handleFilter}>
										Feet
									</p>
									<p
										className='category-name'
										data-filtervalue='Cloth'
										data-filtervalue2='Hands'
										onClick={handleFilter}>
										Hands
									</p>
									<p
										className='category-name'
										data-filtervalue='Cloth'
										data-filtervalue2='Head'
										onClick={handleFilter}>
										Head
									</p>
									<p
										className='category-name'
										data-filtervalue='Cloth'
										data-filtervalue2='Legs'
										onClick={handleFilter}>
										Legs
									</p>
									<p
										className='category-name'
										data-filtervalue='Cloth'
										data-filtervalue2='Shoulder'
										onClick={handleFilter}>
										Shoulder
									</p>
									<p
										className='category-name'
										data-filtervalue='Cloth'
										data-filtervalue2='Waist'
										onClick={handleFilter}>
										Waist
									</p>
									<p
										className='category-name'
										data-filtervalue='Cloth'
										data-filtervalue2='Wrist'
										onClick={handleFilter}>
										Wrist
									</p>
								</div>
								<div className='category-container'>
									<p className='category-title'>Leather</p>
									<p
										className='category-name'
										data-filtervalue='Leather'
										data-filtervalue2='Chest'
										onClick={handleFilter}>
										Chest
									</p>
									<p
										className='category-name'
										data-filtervalue='Leather'
										data-filtervalue2='Feet'
										onClick={handleFilter}>
										Feet
									</p>
									<p
										className='category-name'
										data-filtervalue='Leather'
										data-filtervalue2='Hands'
										onClick={handleFilter}>
										Hands
									</p>
									<p
										className='category-name'
										data-filtervalue='Leather'
										data-filtervalue2='Head'
										onClick={handleFilter}>
										Head
									</p>
									<p
										className='category-name'
										data-filtervalue='Leather'
										data-filtervalue2='Legs'
										onClick={handleFilter}>
										Legs
									</p>
									<p
										className='category-name'
										data-filtervalue='Leather'
										data-filtervalue2='Shoulder'
										onClick={handleFilter}>
										Shoulder
									</p>
									<p
										className='category-name'
										data-filtervalue='Leather'
										data-filtervalue2='Waist'
										onClick={handleFilter}>
										Waist
									</p>
									<p
										className='category-name'
										data-filtervalue='Leather'
										data-filtervalue2='Wrist'
										onClick={handleFilter}>
										Wrist
									</p>
								</div>
								<div className='category-container'>
									<p className='category-title'>Mail</p>
									<p
										className='category-name'
										data-filtervalue='Mail'
										data-filtervalue2='Chest'
										onClick={handleFilter}>
										Chest
									</p>
									<p
										className='category-name'
										data-filtervalue='Mail'
										data-filtervalue2='Feet'
										onClick={handleFilter}>
										Feet
									</p>
									<p
										className='category-name'
										data-filtervalue='Mail'
										data-filtervalue2='Hands'
										onClick={handleFilter}>
										Hands
									</p>
									<p
										className='category-name'
										data-filtervalue='Mail'
										data-filtervalue2='Head'
										onClick={handleFilter}>
										Head
									</p>
									<p
										className='category-name'
										data-filtervalue='Mail'
										data-filtervalue2='Legs'
										onClick={handleFilter}>
										Legs
									</p>
									<p
										className='category-name'
										data-filtervalue='Mail'
										data-filtervalue2='Shoulder'
										onClick={handleFilter}>
										Shoulder
									</p>
									<p
										className='category-name'
										data-filtervalue='Mail'
										data-filtervalue2='Waist'
										onClick={handleFilter}>
										Waist
									</p>
									<p
										className='category-name'
										data-filtervalue='Mail'
										data-filtervalue2='Wrist'
										onClick={handleFilter}>
										Wrist
									</p>
								</div>
								<div className='category-container border'>
									<p className='category-title'>Plate</p>
									<p
										className='category-name'
										data-filtervalue='Plate'
										data-filtervalue2='Chest'
										onClick={handleFilter}>
										Chest
									</p>
									<p
										className='category-name'
										data-filtervalue='Plate'
										data-filtervalue2='Feet'
										onClick={handleFilter}>
										Feet
									</p>
									<p
										className='category-name'
										data-filtervalue='Plate'
										data-filtervalue2='Hands'
										onClick={handleFilter}>
										Hands
									</p>
									<p
										className='category-name'
										data-filtervalue='Plate'
										data-filtervalue2='Head'
										onClick={handleFilter}>
										Head
									</p>
									<p
										className='category-name'
										data-filtervalue='Plate'
										data-filtervalue2='Legs'
										onClick={handleFilter}>
										Legs
									</p>
									<p
										className='category-name'
										data-filtervalue='Plate'
										data-filtervalue2='Shoulder'
										onClick={handleFilter}>
										Shoulder
									</p>
									<p
										className='category-name'
										data-filtervalue='Plate'
										data-filtervalue2='Waist'
										onClick={handleFilter}>
										Waist
									</p>
									<p
										className='category-name'
										data-filtervalue='Plate'
										data-filtervalue2='Wrist'
										onClick={handleFilter}>
										Wrist
									</p>
								</div>
								<div className='category-container border'>
									<p className='category-title'>Jewelry</p>
									<p
										className='category-name'
										data-filtervalue='Plate'
										data-filtervalue2='Chest'
										onClick={handleFilter}>
										Amulets
									</p>
									<p
										className='category-name'
										data-filtervalue='Plate'
										data-filtervalue2='Feet'
										onClick={handleFilter}>
										Rings
									</p>
									<p
										className='category-name'
										data-filtervalue='Plate'
										data-filtervalue2='Hands'
										onClick={handleFilter}>
										Trinkets
									</p>
								</div>
								<div className='category-container'>
									<p className='category-title'>Other</p>
									<p
										className='category-name'
										data-filtervalue='Plate'
										data-filtervalue2='Chest'
										onClick={handleFilter}>
										Cloaks
									</p>
									<p
										className='category-name'
										data-filtervalue='Miscellaneous'
										data-filtervalue2='Held In Off-hand'
										onClick={handleFilter}>
										Off-hand Frills
									</p>
									<p
										className='category-name'
										data-filtervalue='Shield'
										data-filtervalue2='Off Hand'
										onClick={handleFilter}>
										Shields
									</p>
									<p
										className='category-name'
										data-filtervalue='Miscellaneous'
										data-filtervalue2='Shirt'
										onClick={handleFilter}>
										Shirts
									</p>
									<p
										className='category-name'
										data-filtervalue='Miscellaneous'
										data-filtervalue2='Tabard'
										onClick={handleFilter}>
										Tabards
									</p>
									<p
										className='category-name'
										data-filtervalue='Cosmetic'
										data-filtervalue2=''
										onClick={handleFilter}>
										Cosmetic
									</p>
									<p
										className='category-name'
										data-filtervalue='Miscellaneous'
										data-filtervalue2=''
										onClick={handleFilter}>
										Miscellaneous
									</p>
								</div>
							</div>
						</div>
						<div className='menu-item'>
							<p className='menu-title'>
								Containers
								<img className='down-arrow' alt='arrow' src={downArrow}></img>
							</p>
							<div className='menu-dropdown'></div>
						</div>
						<div className='menu-item'>
							<p className='menu-title'>
								Gems
								<img className='down-arrow' alt='arrow' src={downArrow}></img>
							</p>
							<div className='menu-dropdown'></div>
						</div>
						<div className='menu-item'>
							<p className='menu-title'>
								Item Enchancements
								<img className='down-arrow' alt='arrow' src={downArrow}></img>
							</p>
							<div className='menu-dropdown'></div>
						</div>
						<div className='menu-item'>
							<p className='menu-title'>
								Consumables
								<img className='down-arrow' alt='arrow' src={downArrow}></img>
							</p>
							<div className='menu-dropdown'></div>
						</div>
						<div className='menu-item'>
							<p className='menu-title'>
								Glyphs
								<img className='down-arrow' alt='arrow' src={downArrow}></img>
							</p>
							<div className='menu-dropdown'></div>
						</div>
						<div className='menu-item'>
							<p className='menu-title'>
								Trade Goods
								<img className='down-arrow' alt='arrow' src={downArrow}></img>
							</p>
							<div className='menu-dropdown'>
								<div className='category-container double-container'>
									<p className='category-name'>Armor Enchantments</p>
									<p className='category-name'>Cloth</p>
									<p className='category-name'>Devices</p>
									<p className='category-name'>Elemental</p>
									<p className='category-name'>Enchanting</p>
									<p className='category-name'>Explosives</p>
									<p className='category-name'>Herbs</p>
									<p className='category-name'>Jewelcrafting</p>
									<p className='category-name'>Leather</p>
									<p className='category-name'>Materials</p>
									<p className='category-name'>Meat</p>
									<p className='category-name'>Metal & Stone</p>
									<p className='category-name'>Parts</p>
									<p className='category-name'>Weapon Enchantments</p>
									<p className='category-name'>Other</p>
								</div>
							</div>
						</div>
						<div className='menu-item'>
							<p className='menu-title'>
								Recipes
								<img className='down-arrow' alt='arrow' src={downArrow}></img>
							</p>
							<div className='menu-dropdown'></div>
						</div>
						<div className='menu-item'>
							<p className='menu-title'>
								Battle Pets
								<img className='down-arrow' alt='arrow' src={downArrow}></img>
							</p>
							<div className='menu-dropdown'></div>
						</div>
						<div className='menu-item'>
							<p className='menu-title'>
								Quest Items
								<img className='down-arrow' alt='arrow' src={downArrow}></img>
							</p>
							<div className='menu-dropdown'></div>
						</div>
						<div className='menu-item'>
							<p className='menu-title'>
								Miscellaneous
								<img className='down-arrow' alt='arrow' src={downArrow}></img>
							</p>
							<div className='menu-dropdown'></div>
						</div>
						<div className='menu-item search-item' onClick={handleSearchClick}>
							<p className='menu-title search'>
								Search
								<img className='search-icon' alt='arrow' src={wowSearch}></img>
							</p>
						</div>
					</div>
					<div className='search-container'>
						<div className='input-container'>
							<img className='input-icon' src={wowSearch} alt='search'></img>
							<input
								className='search-input'
								type='text'
								// value={order.searchValue}
								onChange={handleSearch}
								placeholder='Enter item name...'></input>
						</div>
					</div>
				</div>

				<ul className='rowContainer'>
					<li className='auction-item sort-row'>
						<div className='img-container sort-bar'></div>
						<p
							onClick={handleSort}
							data-sortfield='name'
							className={`sort-bar name ${
								'name' === order.sortField ? 'active-sort ' + order.order : ''
							}`}>
							Name{' '}
							{'name' === order.sortField ? (
								<img
									className='search-icon'
									alt='arrow'
									src={order.order === 'ASC' ? upArrow : downArrow}></img>
							) : (
								''
							)}
						</p>
						<p
							onClick={handleSort}
							data-sortfield='quantity'
							className={`sort-bar qty ${
								'quantity' === order.sortField
									? 'active-sort ' + order.order
									: ''
							}`}>
							Quantity
							{'quantity' === order.sortField ? (
								<img
									className='search-icon'
									alt='arrow'
									src={order.order === 'ASC' ? upArrow : downArrow}></img>
							) : (
								''
							)}
						</p>
						<p
							onClick={handleSort}
							data-sortfield='subclass'
							className={`sort-bar item-invtype ${
								'subclass' === order.sortField
									? 'active-sort ' + order.order
									: ''
							}`}>
							Type
							{'subclass' === order.sortField ? (
								<img
									className='search-icon'
									alt='arrow'
									src={order.order === 'ASC' ? upArrow : downArrow}></img>
							) : (
								''
							)}
						</p>
						<p
							onClick={handleSort}
							data-sortfield='price'
							className={`sort-bar price ${
								'price' === order.sortField ? 'active-sort ' + order.order : ''
							}`}>
							Price
							{'price' === order.sortField ? (
								<img
									className='search-icon'
									alt='arrow'
									src={order.order === 'ASC' ? upArrow : downArrow}></img>
							) : (
								''
							)}
						</p>
					</li>
					{<ItemList itemCount={itemCount} sortBy={order} />}
				</ul>
			</div>
			<div className='footer'>
				<img id='footer-left' alt='footer-left' src={footerLeft}></img>
				<img id='footer-right' alt='footer-right' src={footerRight}></img>
				<p>World of Warcraft</p>
				<p id='realm'>Realm: Stormscale</p>
				<p>Created by - Timacum</p>
			</div>
		</div>
	);
}

export default App;
