import React, { useState } from 'react';
import RowItem from './itemRow';
import arrowsLeft from './assets/arrows-left.svg';
import arrowsRight from './assets/arrows-right.svg';
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import _ from 'lodash';

const GET_ITEMS_QUERY = gql`
	query getItems($sortBy: SortBy) {
		getItems(sortBy: $sortBy) {
			auctionID
			itemID
			name
			quality
			quantity
			price
			icon
			itemClass
			subclass
			invType
		}
	}
`;

function ItemList(props) {
	const itemCount = props.itemCount;
	const sortBy = props.sortBy;
	const [pagination, setPagination] = useState(1);
	const [queryData, setData] = useState({
		filterField: sortBy.filterField,
		filterField2: sortBy.filterField2,
		filterValue: sortBy.filterValue,
		filterValue2: sortBy.filterValue2,
		searchValue: sortBy.searchValue,
		sortField: sortBy.sortField,
		order: sortBy.order,
	});
	if (!_.isEqual(queryData, sortBy)) {
		if (
			sortBy.filterField !== queryData.filterField ||
			sortBy.filterField2 !== queryData.filterField2 ||
			sortBy.filterValue !== queryData.filterValue ||
			sortBy.filterValue2 !== queryData.filterValue2 ||
			sortBy.searchValue !== queryData.searchValue
		) {
			setPagination(1);
		}
		setData(props.sortBy);
	}

	const { loading, data: { getItems: items } = {} } = useQuery(
		GET_ITEMS_QUERY,
		{
			variables: {
				sortBy: {
					filterField: queryData.filterField,
					filterField2: queryData.filterField2,
					filterValue: queryData.filterValue,
					filterValue2: queryData.filterValue2,
					order: queryData.order,
					sortField: queryData.sortField,
					searchValue: queryData.searchValue,
					skip: (pagination - 1) * 20,
				},
			},
			skip: false,
		}
	);

	const handlePagination = (e) => {
		e.persist();
		setPagination(parseInt(e.target.dataset.page));
	};

	const itemAmoutTotal = itemCount;
	const totalPages = Math.ceil(itemAmoutTotal / 20);
	const pageNumbers = [];

	for (let i = 1; i <= totalPages; i++) {
		let currentPage = pagination === i ? 'currentPage' : '';
		if (i < pagination + 4 && i > pagination - 4) {
			pageNumbers.push(
				<span
					className={'pagination ' + currentPage}
					data-page={i}
					onClick={handlePagination}
					key={i}>
					{i}
				</span>
			);
		}
	}
	if (!loading) {
		return (
			<>
				{items.map((i, index) => {
					return (
						<RowItem
							key={index}
							itemId={i.itemID}
							name={i.name}
							itemQty={i.quantity}
							itemPrice={i.price}
							quality={i.quality}
							quantity={i.quantity}
							icon={i.icon}
							class={i.itemClass}
							subClass={i.subclass}
							invType={i.invType}
						/>
					);
				})}
				<div className='pagContainer'>
					{pagination > 1 ? (
						<span
							className={'pagination ' + pagination}
							onClick={handlePagination}
							data-page={1}
							key={'first'}>
							{
								<img
									className='pagination-arrows'
									alt='arrow'
									src={arrowsLeft}></img>
							}
						</span>
					) : (
						''
					)}
					{pageNumbers}
					{pagination < totalPages ? (
						<span
							className={'pagination ' + pagination}
							onClick={handlePagination}
							data-page={totalPages}
							key={'last'}>
							{
								<img
									className='pagination-arrows'
									alt='arrow'
									src={arrowsRight}></img>
							}
						</span>
					) : (
						''
					)}
				</div>
			</>
		);
	} else {
		return null;
	}
}
export default ItemList;
